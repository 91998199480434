<template>
  <div>
    <van-cell title="就诊人" :value="userName" />
    <van-cell title="开立医生" :value="lists.drName" />
    <van-cell title="开立时间" :value="lists.operTime" />
    <van-cell title="支付状态">
      <template slot="default">
        <span v-if="lists.payStatus == 0" style="color: red"
          >未支付</span
        >
        <span v-else-if="lists.payStatus == 1" style="color: #57c4b7"
          >已支付</span
        >
        <span v-else>已退款</span>
      </template>
    </van-cell>
    <van-cell title="支付金额" :value="lists.totalFee + '元'" />
    <!-- </van-cell-group>
      <van-cell-group v-for="item in list" :key="item + 'OrdIn'"> -->

    <van-list style="margin-top: 5px">
      <van-cell-group>
        <van-cell title="项目名称">
          <template slot="default">
            <span style="color: #57c4b7">金额(元)</span>
          </template>
        </van-cell>
        <van-cell v-for="Ditem in lists.recipes" :key="Ditem + 'OrdIn'">
        <!-- <van-cell> -->
          <!-- <template slot="title"> -->
            <!-- <span style="color: #969799">{{ Ditem.itemName }}</span> -->
            <!-- <span style="float: right">{{ Ditem.totalCost }}元</span> -->
          <!-- </template> -->
          <template slot="title">
              <div v-for="(item,index) in Ditem.clinicDetails" :key="index">
              <span>{{ item.itemName }}</span>
              <span style="float:right">{{ item.cost }}元</span>
              </div>
            </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- <van-button type="primary" style="margin: 4% 5% auto 5% ; width: 90%; height: 40%;" color="#57c4b7" round size="large" to="#">立即支付</van-button> -->
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import ajax from "../../lib/ajax";
import store from "../../store";
export default {
  data() {
    return {
      userName: "",
      list: {},
      lists: {},
      loading: false,
      finished: false,
      feeTotal: 0,
    };
  },
  created() {
    this.userName = store.getters["store/userName"];
    this.onLoad();
  },
  methods: {
    async onLoad() {
      // let me = this;
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.list.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length == this.list.length) {
          this.finished = true;
        }
      }, 500);
      // let regno = store.getters["store/regNo"];
      // let patientid = store.getters['store/patientid'];//声明病例号
      // console.log("挂号流水号"+regno);
      let startdate = window.sessionStorage.getItem("startdate");
      let enddate = window.sessionStorage.getItem("enddate");
      // console.log("病例号"+patientid);
      let regNo = store.getters["store/regNo"];
      //  let recipeNO = window.sessionStorage.getItem("recipeNO");
      // let idcard = store.getters["store/idcard"];
      // let seqNo = store.getters["store/seqNo"];
      // console.log(seqNo);
      // seqNo = "";
      // let patientid = store.getters["store/patientid"]; //声明病例号
      // let idcard = store.getters["store/idcard"];
      // idcard = "0001052852";
      let userId = store.getters["store/userId"];
      console.log(userId);
      await ajax
        .post(
          "/Api/FeeService/GetFeeLog",
          {
            userID: userId,
            beginDate: startdate + " 00:00:00",
            endDate: enddate + " 23:59:59",
          }
          // idcard +
          // "&BeginDate=" +
          //  +
          // &EndDate=" +
          // this.enddate +
          // " 23:59:59"
        )
        .then((rep) => {
          this.lists = [];
          if (rep.data.result.resultCode !== "-1") {
            let list = rep.data.result.clinicFees;
            // this.lists = list;
            for (let i = 0; i < list.length; i++) {
              const element = list[i];
              if(element.regNo == regNo){
                this.lists = element;
              }
              // if(element.recipeNO == recipeNO){
              //   this.lists = element;
              // }
            }
            console.log(this.lists);
          }
        })
        .catch((err) => {
          console.log(err.message);
          // console.log(err);
          // Dialog.alert({
          //   title: "错误",
          //   message:
          Toast("系统错误,请稍后重试");
          // });
        });

      // await ajax
      //   .post("/Api/FeeService/GetFeeDetail", {
      //     regNo: regNo,
      //     seqNo: seqNo,
      //     idCard: "533025199707123317",
      //   })
      //   .then((rep) => {
      //     me.list = rep.data.result.data[0];
      //     console.log(me.list);
      //     console.log(me.list[0].operTime);
      //     me.list[0].operTime =
      //       me.list[0].operTime.substring(4, 6) +
      //       "/" +
      //       me.list[0].operTime.substring(6, 8) +
      //       "/" +
      //       me.list[0].operTime.substring(8, 10) +
      //       ":" +
      //       me.list[0].operTime.substring(10, 12) +
      //       ":" +
      //       me.list[0].operTime.substring(12, 14);
      //     // alert(me.list[0].operTime);
      //     me.lists = me.list[0].clinicDetails;
      //     console.log(me.lists);
      //   })
      //   .catch(() => {
      //     Toast("请求失败，请稍后重试！");
      //   });
    },
  },
};
</script>

<style>
</style>
